import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import ToolsList from "./components/toolsList";
import Notification from "../notification";
import PropTypes from "prop-types";
import ToolDetails from "./components/toolDetails";
import ToolPayment from "./components/payment";
import Header from "./components/header";
import OwnerAndActivated from "./components/ownerAndActivated";
import {
  API_addBoard, API_addTool,
  API_getKanbanData,
  API_getSyncedPayment,
  API_removeTool,
  API_updateTool
} from "../../services/backendService";
import "./css/toolDetails.css";
import RolloutProcess from "./components/rolloutProcess";
import { t } from "i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocation, useNavigate } from "react-router-dom";
import { isInViewport } from "../../services/utils";

const ToolsAndServices = (props) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const [selectedTool, setSelectedTool] = React.useState(props.tools[0]);
  const [boards, setBoards] = React.useState(null);
  const [boardId, setBoardId] = React.useState(null);
  const [columns, setColumns] = React.useState(null);
  const [swimlanes, setSwimlanes] = React.useState(null);
  const [tasks, setTasks] = React.useState(null);
  const [isDetailsVisible, setIsDetailsVisible] = React.useState(true);
  const [isInvoicesVisible, setIsInvoicesVisible] = React.useState(false);
  const [isEmployeesVisible, setIsEmployeesVisible] = React.useState(false);
  const navigate = useNavigate();

  const handleListItemClick = (tool) => {
    setSelectedTool(tool);
    getKanbanData(tool.id).then();
  };

  const addTool = (id) => {
    props.setLoading(true);
    API_addTool(id, props.setNewStatus).then(function(response) {
      let allNewTools = props.allTools
        .concat(response)
        .sort((a, b) => a.tool_synced.name.localeCompare(b.tool_synced.name));
      props.setTools(allNewTools);
      props.setLoading(false);
      setNotify({
        isOpen: true,
        message: "Das neue Tool wurde erfolgreich angelegt",
        type: "success",
      });
      setSelectedTool(response);
      if (response.status === "inTesting") {
        API_addBoard(response.id).then(() => {
          getKanbanData(response.id).then(() => props.setLoading(false));
        });
      } else {
        props.setLoading(false);
      }
    });
  };

  const updateTool = (object, changedImage) => {
    props.setLoading(true);
    if (object === "error") {
      setNotify({
        isOpen: true,
        message: t(changedImage),
        type: "error",
      });
      props.setLoading(false)
    } else {
      API_updateTool(selectedTool.id, object)
        .then(function (updatedTool) {
          let toolsCopy = JSON.parse(JSON.stringify(props.allTools));
          let positionUpdatedTool;
          toolsCopy.forEach(function(tool, i) {
            if (tool.id === selectedTool.id) {
              positionUpdatedTool = i;
            }
          })
          toolsCopy
            .splice(positionUpdatedTool, 1, updatedTool);
          setSelectedTool(updatedTool);
          props.setTools(toolsCopy);
          if (object.includes('{ status: ')) {
            if (object.includes('{ status: "inTesting" }')) {
              navigate("/testingtools");
            } else {
              navigate("/toolsandservices");
            }
          }
          if (object.includes("payment")) {
            API_getSyncedPayment().then((syncedPaymentsData) => {
              props.setsyncedPayment(syncedPaymentsData.sort((a, b) => a.name.localeCompare(b.name)));
              setNotify({
                isOpen: true,
                message: t('toast_save_success'),
                type: "success",
              });
              props.setLoading(false);
            })
          } else {
            setNotify({
              isOpen: true,
              message: t('toast_save_success'),
              type: "success",
            });
            props.setLoading(false);
          }
          if (changedImage) {
            document.getElementById("paymentImage").src =
              "assets/loading.svg";
          }
          if (!!object.status && object.status === "im Test") {
            let addNewBoard = true;
            if (boards) {
              for (let i = 0; i < boards.length; i++) {
                const board = boards[i];
                if (
                  !!board.fields.tool &&
                  board.fields.tool[0] === updatedTool.id
                ) {
                  addNewBoard = false;
                }
              }
            }
            if (addNewBoard) {
              API_addBoard(updatedTool.id).then((r) => {
                fetchData().then(() => {
                  setBoardId(r.id);
                  props.setLoading(false);
                });
              });
            } else {
              props.setLoading(false);
            }
          } else if (object["Payment synced"]) {
            getPagedData(API_getSyncedPayment).then((syncedPaymentData) => {
              props.setsyncedPayment(
                syncedPaymentData.sort((a, b) =>
                  a.fields.Name.localeCompare(b.fields.Name)
                )
              );
            });
            props.setLoading(false);
          } else {
            setNotify({
              isOpen: true,
              message: "Die Daten wurden erfolgreich gespeichert",
              type: "success",
            });
            props.setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setNotify({
            isOpen: true,
            message: t('toast_save_error'),
            type: "error",
          });
          props.setLoading(false);
        });
    }
  };

  const removeTool = () => {
    props.setLoading(true);
    API_removeTool(selectedTool.id)
      .then(function () {
        let newAllTools = props.allTools.filter(function (item) {
          return item.id !== selectedTool.id;
        });
        props.setTools(newAllTools);

        let newFilteredTools = props.tools.filter(function (item) {
          return item.id !== selectedTool.id;
        });
        setSelectedTool(newFilteredTools[0])
        props.setLoading(false);
        setNotify({
          isOpen: true,
          message: t('toast_tool_delete_success'),
          type: "success",
        });
      })
      .catch(function (error) {
        // handle error
        setNotify({
          isOpen: true,
          message: t('toast_tool_delete_error'),
          type: "error",
        });
        console.log(error);
        props.setLoading(false);
      });
  };

  let fullView = useMediaQuery("(min-width:750px)");
  const [activeTab, setActiveTab] = useState("details");

  async function getKanbanData(toolId) {
    if (selectedTool.status === "inTesting") {
      API_getKanbanData(toolId).then(function (data) {
        let boardId = data.boards[0].id;
        let columns = data.columns;
        let swimlanes = data.swimlanes;
        let tasks = data.tasks;
        setBoardId(boardId);
        setColumns(columns);
        setSwimlanes(swimlanes);
        setTasks(tasks);
      })
    }
  }

  if (props.tools.length > 0 && selectedTool) {
    let currentToolIsVisible = false;
    for (let i = 0; i < props.tools.length; i++) {
      const checkTool = props.tools[i];
      if (checkTool.id === selectedTool.id) {
        currentToolIsVisible = true;
      }
    }
    if (!currentToolIsVisible) {
      setSelectedTool(!!props.tools[0] ? props.tools[0] : null);
    }
  }

  let toolViaUrl = null;
  let idFromUrl = new URLSearchParams(useLocation().search).get('id');
  useEffect(() => {
    if (props.tools.length > 0 && selectedTool) {
      for (let i = 0; i < props.tools.length; i++) {
        const checkTool = props.tools[i];
        if (idFromUrl === checkTool.id) {
          toolViaUrl = checkTool;
        }
      }
      if (!!toolViaUrl) {
        setSelectedTool(toolViaUrl)
      }
    }
    if (!!selectedTool && selectedTool.status === "inTesting") {
      props.setLoading(true);
      getKanbanData(selectedTool.id).then(() => props.setLoading(false));
    }
    isElementLoaded("#toolData").then(() => {
      handleScroll();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleScroll() {
    if (isInViewport(document.getElementById("detailsTopBox"))) {
      setIsDetailsVisible(true);
      setIsInvoicesVisible(false);
      setIsEmployeesVisible(false);
    } else if (isInViewport(document.getElementById("invoicesBoxTop"))) {
      setIsDetailsVisible(false);
      setIsInvoicesVisible(true);
      setIsEmployeesVisible(false);
    } else if (isInViewport(document.getElementById("ownerAndActivatedBoxTop"))) {
      setIsDetailsVisible(false);
      setIsInvoicesVisible(false);
      setIsEmployeesVisible(true);
    }
  }

  const isElementLoaded = async selector => {
    while ( document.querySelector(selector) === null) {
      await new Promise( resolve =>  requestAnimationFrame(resolve) )
    }
    return document.querySelector(selector);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Header
        toolName={!!selectedTool && selectedTool.tool_synced ? selectedTool.tool_synced.name : ""}
        addTool={addTool}
        syncedTools={props.syncedTools}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        header={props.header}
        urlLogin={selectedTool.tool_synced.url_login}
        urlWeb={selectedTool.tool_synced.url_web}
        showRolloutProcess={
          !!selectedTool ? selectedTool.status === "inTesting" : false
        }
        isDetailsVisible={isDetailsVisible}
        isInvoicesVisible={isInvoicesVisible}
        isEmployeesVisible={isEmployeesVisible}
      />
      <Box sx={{position: "relative", height: "calc(100% - var(--lineHeight))", pl: "8px"}}>
        <Box sx={{position: "absolute", width: { xs: "100%", sm: "20%" }, overflow: "scroll", top: 0, bottom: 0, left: "var(--detailsBoxPadding)"}} className={"hideScrollbar cardShadow"}>
          <Scrollbars>
            <ToolsList
            tools={props.tools}
            selectedTool={selectedTool}
            onClick={(value) => handleListItemClick(value)}
          />
          </Scrollbars>
        </Box>
        <Box
          sx={{position: "absolute", width: { xs: "0%", sm: "calc(80% - var(--detailsBoxPadding))" }, overflow: "scroll", top: 0, bottom: 0, right: 0}}
          className={"hideScrollbar"}
        >
          {activeTab === "details" && props.tools.length > 0 && (
            <Scrollbars
              onScrollStart={() => handleScroll()}
              onScrollStop={() => handleScroll()}
            >
              <ToolDetails
                tool={selectedTool}
                update={updateTool}
                showList={!fullView}
                setLoading={props.setLoading}
                remove={removeTool}
              />
              <ToolPayment
                update={updateTool}
                payments={props.syncedPayment}
                tool={selectedTool}
                tools={props.tools}
                allTools={props.allTools}
                setTools={props.setTools}
                setLoading={props.setLoading}
              />
              <OwnerAndActivated
                setSelectedTool={setSelectedTool}
                selectedTool={selectedTool}
                activated={
                  selectedTool.employees_activated
                    ? selectedTool.employees_activated.map(function (employee) {
                      return {
                        employee_tool_id: employee.id,
                        id: employee.employees_id.id,
                        firstname: employee.employees_id.firstname,
                        lastname: employee.employees_id.lastname,
                        avatar: employee.employees_id.avatar ? employee.employees_id.avatar.id : null,
                      }
                    })
                      .sort((a, b) => a.lastname.localeCompare(b.lastname))
                    : []
                }
                responsibility={
                  selectedTool.employees_responsibility
                    ? selectedTool.employees_responsibility.map(function (employee) {
                      return {
                        employee_tool_id: employee.id,
                        id: employee.employees_id.id,
                        firstname: employee.employees_id.firstname,
                        lastname: employee.employees_id.lastname,
                        avatar: employee.employees_id.avatar ? employee.employees_id.avatar.id : null,
                      }
                    })
                    : []
                }
                employees={props.employees}
                setTools={props.setTools}
                setLoading={props.setLoading}
                setEmployees={props.setEmployees}
              />
            </Scrollbars>
          )}
          {activeTab === "board" && !!columns && (
            <RolloutProcess
              setLoading={props.setLoading}
              boardId={boardId}
              toolId={selectedTool.id}
              columns={columns
                .sort((a, b) => {
                  return a.order - b.order;
                })}
              swimlanes={swimlanes
                .sort((a, b) => {
                  return a.order - b.order;
                })}
              tasks={tasks
                .sort((a, b) => {
                  return a.order - b.order;
                })}
              boardFullSync={() =>
                getKanbanData(props.tools[selectedTool].id).then(() => props.setLoading(false))
              }
              setTasks={setTasks}
            />
          )}
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

ToolsAndServices.propTypes = {
  tools: PropTypes.array,
  setTools: PropTypes.func,
  employees: PropTypes.array,
  syncedTools: PropTypes.array,
  syncedPayment: PropTypes.array,
  setLoading: PropTypes.func,
  setEmployees: PropTypes.func,
  setsyncedPayment: PropTypes.func,
  setNewStatus: PropTypes.string,
  header: PropTypes.string,
  allTools: PropTypes.array,
  handleArrowBackClick: PropTypes.func,
  showList: PropTypes.bool,
};

export default ToolsAndServices;
